import parse from "html-react-parser";
import { useState } from 'react';
import { IAboutUsComponentProps } from '../../../lib/types/components';
import CustomImageComponent from '../../common/CustomImage/CustomImageComponent';

const AboutUsComponent = (props: IAboutUsComponentProps) => {
    const {aboutUsData, isGetApp} = props;
    const data = aboutUsData;
    const [readMore, setReadMore] = useState(aboutUsData.para.length <= 1);

    const showMore = () => {
        setReadMore(true);
    }

    return (
        data && Object.keys(data).length ?
            <>
                <div className={"container-fluid"}>
                    <style jsx>{`
                      .container-fluid {
                        background-color: #f1f1f1;
                        text-align: center;
                      }

                      .img {
                        margin-top: 40px;
                      }

                      .heading {
                        padding-top: 50px;
                        text-transform: uppercase;
                        font-family: var(--font-saira);
                        font-size: 36px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: italic;
                        color: rgba(51, 51, 51, 0.85);
                      }

                      .aboutUsPara {
                        margin-bottom: 10px;
                      }

                      .paraContainerMobile {
                        display: none;
                      }

                      .paraContainerPC {
                        display: block;
                      }

                      .paraContainer {
                        padding-top: 10px;
                        padding-bottom: 50px;
                      }

                      @media screen and (max-width: 1024px) {
                        .container {
                          margin-top: ${isGetApp?"0px":"30px"};
                          height: auto;
                          padding: 20px
                        }

                        .heading {
                          padding-top: 20px;
                          font-size: 30px;
                        }


                        .aboutUsPara {
                          font-size: 16px;
                        }

                        .paraContainer {
                          padding-bottom: 20px;
                        }

                      }

                      @media screen and (max-width: 767px) {
                        .container {
                          margin-top: 0;
                          height: auto;
                          padding: 15px
                        }

                        .heading {
                          padding-top: 20px;
                          font-size: 25px;
                        }

                        .aboutUsPara {
                          font-size: 14px;
                        }

                        .paraContainerMobile {
                          display: block;
                        }

                        .paraContainerPC {
                          display: none;
                        }

                        .paraContainer {
                          padding-bottom: 20px;
                        }

                      }

                      @media screen and (max-width: 576px) {
                        .container {
                          height: auto;
                          padding: 15px
                        }

                        .heading {
                          padding-top: 20px;
                          font-size: 18px;
                        }

                        .aboutUsPara {
                          font-size: 12px;
                        }

                        .paraContainer {
                          padding-bottom: 20px;
                        }
                      }
                    `}</style>
                    <div className='container'>
                        <h2 className={"heading"}>{data.heading}</h2>
                        <div className={"paraContainer paraContainerMobile"}>
                            {!readMore &&
                                <>
                                    <div className={"aboutUsPara"}>
                                        {parse(data.para[0])}
                                    </div>
                                    <div onClick={() => showMore()} className={"img"}>
                                        <CustomImageComponent
                                            height={22}
                                            width={42}
                                            src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/showMore.webp"
                                            alt={"about-us-image"}
                                        />
                                    </div>
                                </>
                            }
                            {readMore &&
                                <>
                                    {data.para.map((item, index) => {
                                        return (
                                            <div className={"aboutUsPara"} key={"data_para_" + index}>
                                                {parse(item)}
                                            </div>
                                        )
                                    })
                                    }
                                </>
                            }
                        </div>

                        <div className={"paraContainer paraContainerPC"}>
                            {data.para.map((item, index) => {
                                return (
                                    <div className={"aboutUsPara"} key={"data.para_" + index}>
                                        {parse(item)}
                                    </div>
                                )
                            })
                            }
                        </div>


                    </div>
                </div>
            </> : <></>
    )
}

export default AboutUsComponent;
